<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="btn">
			<a-button type="primary" @click="exportSheet">
				{{ $t('page.download') }}
			</a-button>
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a-button type="link" @click="Detail(columnData.id)" :disabled="!$hasPermission('ROLE_PENGING_LOAN_VIEW')">{{ $t('page.checkDetail') }}</a-button>
		</template>
	</search-list-table>
</template>
<script>
import { apiLoanWaiting, apiDisburseLoanWaiting } from '@/api/loan'
export default {
	data() {
		return {}
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityCard']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityCard',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.status'),
					dataIndex: 'statusCode',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.loanApproved'),
					dataIndex: 'applyAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 保险金额
					title: 'IPF Amount',
					dataIndex: 'ipfAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 放款金额
					title: 'Total Amount',
					dataIndex: 'totalAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiLoanWaiting(initParams)
		},
		Detail(id) {
			this.$router.push({
				path: 'pendingDetail',
				query: {
					id,
					from: this.$route.path
				}
			})
		},
		exportSheet() {
			apiDisburseLoanWaiting({ ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }).then((res) => {
				let str = `Case ID,Client Name,Registration No.,Principal,IPF,Total,Date,`
				res.forEach((item) => {
					str += `\r\n${item.casePrefix}-${item.caseId},${item.fullName},${item.registrationNumbers ? item.registrationNumbers.join('/') : '-'},${
						item.applyAmount
					},${item.ipfAmount},${item.total},${item.disburseCheckPassTime},`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Pending Disbursement.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	}
}
</script>
<style scoped lang="less"></style>
